import { CardAvisos } from "./card";
import styles from "../../../../styles/module/transparency/sacop.module.css";
import { useQuery } from "react-query";
import api from "../../../../services/api";
import Loading from "../../others/Loading";

export default function AvisosHome() {
  const { data, isLoading } = useQuery("avisos", async () => {
    const response = await api.get("/v2/sacop/list/all/avisos?pageSize=10");
    return response.data.res;
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!data?.length) {
    return null;
  }
  const carrousel = [...data, ...data, ...data];

  return (
    <section className={styles.container}>
      <h2>AVISOS DE LICITAÇÕES</h2>
      <div className={styles.box}>
        <ul className={styles.container_avisos_home}>
          {carrousel.map((item, index) => (
            <li key={`${item.ID}-${index}`}>
              <CardAvisos data={item} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
